<template>
  <b-card title="Alistamiento de pedido">

    <div v-if="order">

      <h4>Tipo de producto: SIMs</h4>
      <h6>Destino: {{ order.destination.name }}</h6>
      <h5><span class="text-primary">Cantidad:</span> {{ order.sims_count }} / {{ order.amount }}</h5>
      <hr>
      <!-- User Info: Input Fields -->
      <validation-observer ref="formReadSerial" v-if="order.order_status_id == 2">

        <b-form
          @submit.prevent="saveSerial">
          <b-row>

            <b-col
              cols="12"
              md="4"
            >

              <b-form-group
                  label="SIM *"
                  label-for="sim"
                >
                <validation-provider
                    #default="{ errors }"
                    name="SIM"
                    rules="required"
                  >

                  <b-form-input
                    id="sim"
                    v-model="sim"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>

            </b-col>
            
          </b-row>

          <!-- Action Buttons -->
          <b-spinner
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              v-show="loading"></b-spinner>
          <b-button
            v-show="!loading"
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            type="submit"
          >
            Guardar
          </b-button>
          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="$router.push({name: 'sim-order-list'})"
          >
            Cancelar
          </b-button>
        
        </b-form>
      </validation-observer>

      <div v-else>

        <h6>Estado: {{ order.order_status.name }}</h6>
        <h6>Descripción: {{ order.order_status.description }}</h6>
        <h6>Cantidad de SIMs: {{ order.amount }}</h6>

        <b-button
          variant="outline-secondary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="$router.push({name: 'sim-order-list'})"
          class="mt-2"
        >
          Ver pedidos
        </b-button>

      </div>

    </div>

  </b-card>

</template>

<script>
import router from '@/router'
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { required, } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {constants} from "@/helpers/constants"

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BSpinner,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken'),
      serial: '',
      sim: '',
      order: null,
      required,
      orderId: null,
      loading: false,
    }
  },
  created() {
    this.orderId = router.currentRoute.params.order_id
    this.getOrder()
  },
  methods: {
    getOrder() {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.get(this.host + '/api/front-web/get-sim-order-enlistment/' + this.orderId, { headers }).then(response => {
        console.log('order: ', response)

        this.order = response.data
        
      }).catch( err => {
        console.log(err)
      })
    },
    saveSerial() {
      event.preventDefault()
      this.loading = true
      this.$refs.formReadSerial.validate().then(success => {
        if (success) {

          let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.accessToken
          }
          axios.post(this.host + '/api/front-web/read-sim',{
            order_id: this.order.id,
            sim_serial: this.sim,
          }, { headers }).then(response => {
            console.log('read-sim: ', response)

            this.loading = false

            if(response.data.status) {
              this.sim = ''
              this.$toast({
                component: ToastificationContentVue,
                position: 'top-right',
                props: {
                  title: `OK`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              this.getOrder()
            } else {
              this.$toast({
                component: ToastificationContentVue,
                position: 'top-right',
                props: {
                  title: `Advertencia`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
            }
          }).catch( err => {
            this.loading = false
            console.log(err)
          })

        }
      })


    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
